"use client"

import React, { useRef, useLayoutEffect, useMemo } from 'react';
import clx from 'classnames';

import useReviewsProductWidgetScript from '../../../../vendor/reviews_io/hooks/useReviewsProductWidgetScript';

import styles from './Wysiwyg.module.css';

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: 'div' | 'header' | 'section';
    html?: string;
}

/**
 * Wysiwyg component.
 *
 * We also shoot up some extra bits in here for trustpilot + reviews widgets
 * <div class="trustpilot-widget" data-sku="TIP-6015,TIP-3600,TIP-3599,TIP-3597"></div>
 * <div class="reviews-io-product-widget" data-sku="TIP-6015,TIP-3600,TIP-3599,TIP-3597"></div>
 *
 * TODO: Add some sort of security filtering.
 * TODO: Add ability to strip HTML tags.
 */
const Wysiwyg = ({ as: Tag = 'div', className, html, ...rest }: Props) => {
    const classNames = clx(styles.wysiwyg, 'type-paragraph', className);
    const ref = useRef<HTMLDivElement>(null);

    const [initProductReview] = useReviewsProductWidgetScript();

    useLayoutEffect(() => {
        if (!ref.current) return;

        const widgets = ref.current.querySelectorAll('.trustpilot-widget, .reviews-io-product-widget');
        if (!widgets.length) return;

        widgets.forEach((widget) => {
            const skuList = widget.getAttribute('data-sku') || '';
            const querySelector = `[data-sku*="${skuList}"]`;
            const skuListCommaSeparated = skuList.replace(/,/g, ';');

            initProductReview!(querySelector, skuListCommaSeparated);
        });
    }, [initProductReview]);

    const htmlWithLazyLoadedImages = useMemo(() => typeof html === 'string' ? html?.replace(/<img/g, '<img loading="lazy"'): '', [html]);

    if (!htmlWithLazyLoadedImages) {
        return null;
    }

    // find images in the html via regex and add a class to them
    // this is to ensure that the images are responsive

    return <Tag {...rest} ref={ref} className={classNames} dangerouslySetInnerHTML={{ __html: htmlWithLazyLoadedImages }} />;
};

export default Wysiwyg;
