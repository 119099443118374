'use client';

import { useSearchContext } from '../../../../data/context/searchContext';

import Typography from '../../_blocks/Typography/Typography';
import SearchInput from '../../../Search/SearchInput/SearchInput';
import TagCloud from '../../../TagCloud/TagCloud';

import styles from './ExitCallout.module.css';

interface Props {
    treatmentCount?: number;
    conditionCount?: number;
    className?: string;
    title?: string;
    searchTerms?: string[] | null;
}

/**
 * Exit callout component
 * @see https://www.figma.com/design/HB2vMGpmieuLtPlJUEfFXi/Hippo-UI-kit-3.1?node-id=6847-1998&node-type=frame&m=dev
 */
const ExitCallout = ({
    treatmentCount,
    conditionCount,
    className,
    searchTerms,
    title = 'Need something else?'
}: Props) => {
    const { setSearchTerm, setShowOverlay } = useSearchContext();

    const updateSearchTerm = (string: string) => {
        setSearchTerm(string);
        setShowOverlay(true);
    }

    return (
        <article className={`${styles.wrapper} ${className || ''}`}>
            <div className={styles.content}>
                <div className="space-y-025">
                    <Typography as="h2" typeset="subtitle" size="300" className="md:type-500">
                        {title}
                    </Typography>
                    {(treatmentCount && conditionCount)
                        ? <Typography as="p" typeset="emphasis" size="100">
                            We stock over {treatmentCount} treatments for {conditionCount} conditions
                        </Typography>
                        : null}
                </div>
                <SearchInput
                    mobileStack
                    enableButton
                    placeholder="Find treatments and helpful information for your condition"
                    invokesOverlay />
                {searchTerms ? (
                    <TagCloud
                        color="regular"
                        onClick={(tag) => {
                            updateSearchTerm(tag.name);
                        }}
                        tags={searchTerms.map((term) => ({
                            name: term
                        }))}
                    />
                ) : null}
            </div>
        </article>
    );
};

export default ExitCallout;
