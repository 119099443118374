export default {
    options: {
        types: 'product_review',
        lang: 'en',
        // Possible layout options: bordered, large and reverse.
        layout: 'bordered',
        // How many reviews & questions to show per page?
        per_page: 10,
        // Product specific settings. Provide product SKU for which reviews should be displayed:
        product_review: {
            // Display product reviews - include multiple product SKUs seperated by Semi-Colons (Main Indentifer in your product catalog )
            // sku: skus,
            hide_if_no_results: false,
        },
        // Questions settings:
        questions: {
            hide_if_no_results: false,
            enable_ask_question: false,
            show_dates: false,
            // Display group questions by providing a grouping variable, new questions will be assigned to this group.
            // grouping: '[Group questions by providing a grouping variable here or a specific product SKU]'
        },
        // Header settings:
        header: {
            enable_summary: true, // Show overall rating & review count
            enable_ratings: true,
            enable_attributes: false,
            enable_image_gallery: false, // Show photo & video gallery
            enable_percent_recommended: false, // Show what percentage of reviewers recommend it
            enable_write_review: false, // Show "Write Review" button
            enable_ask_question: false, // Show "Ask Question" button
            enable_sub_header: true, // Show subheader
        },
        // Filtering settings:
        filtering: {
            enable: true, // Show filtering options
            enable_text_search: false, // Show search field
            enable_sorting: false, // Show sorting options (most recent, most popular)
            enable_overall_rating_filter: true, // Show overall rating breakdown filter
            enable_ratings_filters: false, // Show product attributes filter
            enable_attributes_filters: false, // Show author attributes filter
        },
        // Review settings:
        reviews: {
            enable_replies: true, // Show review replies
            enable_avatar: false, // Show author avatar
            enable_reviewer_name: true, // Show author name
            enable_reviewer_address: false, // Show author location
            reviewer_address_format: 'city in country', // Author location display format
            enable_verified_badge: true, // Show "Verified Customer" badge
            enable_reviewer_recommends: true, // Show "I recommend it" badge
            enable_attributes: false, // Show author attributes
            enable_product_name: true, // Show display product name
            enable_images: false, // Show display review photos
            enable_ratings: true, // Show product attributes (additional ratings)
            enable_share: false, // Show share buttons
            enable_helpful_vote: true, // Show "was this helpful?" section
            enable_helpful_display: true, // Show how many times times review upvoted
            enable_report: false, // Show report button
            enable_date: true, // Show when review was published
        },
    },
    // Translation settings
    translations: {
        'Verified Customer': 'Verified Customer',
    },
    // Style settings:
    styles: {
        // Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.
        '--base-font-size': '16px',
        // Button styles (shared between buttons):
        '--common-button-font-family': 'sofia-pro',
        '--common-button-font-size': '13px',
        '--common-button-font-weight': '700',
        '--common-button-letter-spacing': '0.0625rem',
        '--common-button-text-transform': 'uppercase',
        '--common-button-vertical-padding': '10px',
        '--common-button-horizontal-padding': '20px',
        '--common-button-border-width': '2px',
        '--common-button-border-radius': '3px',
        // Primary button styles:
        '--primary-button-bg-color': '#f39d26',
        '--primary-button-border-color': '#f39d26',
        '--primary-button-text-color': '#ffffff',
        // Secondary button styles:
        '--secondary-button-bg-color': 'transparent',
        '--secondary-button-border-color': '#172b3a',
        '--secondary-button-text-color': '#172b3a',
        // Star styles:
        '--common-star-color': '#FFDF8A',
        '--common-star-disabled-color': '#bdcedb',
        '--medium-star-size': '22px',
        '--small-star-size': '19px',
        // Heading styles:
        '--heading-text-color': '#303C69',
        '--heading-text-font-weight': '600',
        '--heading-text-font-family': 'sofia-pro',
        '--heading-text-line-height': '1.4',
        '--heading-text-letter-spacing': '0',
        '--heading-text-transform': 'none',
        // Body text styles:
        '--body-text-color': '#303C69',
        '--body-text-font-weight': '400',
        '--body-text-font-size': '12px',
        '--body-text-font-family': 'sofia-pro',
        '--body-text-line-height': '1.4',
        '--body-text-letter-spacing': '0',
        '--body-text-transform': 'none',
        // Input field styles:
        '--inputfield-text-font-family': 'inherit',
        '--input-text-font-size': '12px',
        '--inputfield-text-font-weight': '400',
        '--inputfield-text-color': '#303C69',
        '--inputfield-border-color': 'bdcedb',
        '--inputfield-background-color': 'white',
        '--inputfield-border-width': '1px',
        '--inputfield-border-radius': '3px',

        '--common-border-color': '#dfedf7',
        '--common-border-width': '1px',
        '--common-sidebar-width': '190px',
        // Slider indicator (for attributes) styles:
        '--slider-indicator-bg-color': 'rgba(0,0,0,0.1)',
        '--slider-indicator-button-color': '#0E1311',
        '--slider-indicator-width': '190px',
        // Badge styles:
        '--badge-icon-color': '#016EB7',
        '--badge-icon-font-size': 'inherit',
        '--badge-text-color': '#303C69',
        '--badge-text-font-size': 'inherit',
        '--badge-text-letter-spacing': 'inherit',
        '--badge-text-transform': 'inherit',
        // Author styles:
        '--author-font-size': 'inherit',
        '--author-text-transform': 'none',
        // Author avatar styles:
        '--avatar-thumbnail-size': '60px',
        '--avatar-thumbnail-border-radius': '100px',
        '--avatar-thumbnail-text-color': '#303C69',
        '--avatar-thumbnail-bg-color': 'rgba(0,0,0,0.1)',
        // Product photo or review photo styles:
        '--photo-video-thumbnail-size': '80px',
        '--photo-video-thumbnail-border-radius': '3px',
        // Media (photo & video) slider styles:
        '--mediaslider-scroll-button-icon-color': '#0E1311',
        '--mediaslider-scroll-button-bg-color': 'rgba(255, 255, 255, 0.85)',
        '--mediaslider-overlay-text-color': '#ffffff',
        '--mediaslider-overlay-bg-color': 'rgba(0, 0, 0, 0.8))',
        '--mediaslider-item-size': '110px',
        // Pagination & tabs styles (normal):
        '--pagination-tab-text-color': '#172b3a',
        '--pagination-tab-text-transform': 'none',
        '--pagination-tab-text-letter-spacing': '0',
        '--pagination-tab-text-font-size': '16px',
        '--pagination-tab-text-font-weight': '600',
        // Pagination & tabs styles (active):
        '--pagination-tab-active-text-color': '#172b3a',
        '--pagination-tab-active-text-font-weight': '600',
        '--pagination-tab-active-border-color': '#15a9e3',
        '--pagination-tab-border-width': '3px',
    },
};
