import { useCallback, useState, useEffect } from 'react';

import usePollingHook from '../../../hooks/usePollinghook';
import productReviewWidgetOptions from '../config/productReviewWidgetOptions';
import addScript from '../../../helpers/addScript';
import { REVIEWS_IO_STORE } from '../../../config/config';

const useReviewsProductWidgetScript = () => {
    const [ReviewsWidget, setReviewsWidget] = useState();

    useEffect(() => {
        if (!window.ReviewsWidget) addScript('https://widget.reviews.co.uk/polaris/build.js', () => {}, [{ key: 'defer', value: 'defer' }]);
    }, []);

    const checkReviewsIsReady = () => {
        if (window.ReviewsWidget && setReviewsWidget) {
            // We check the setReviewsWidget
            setReviewsWidget(() => window.ReviewsWidget);
        }
    };

    /**
     * Because many of these may exist at once, callback on the script will not suffice!
     */
    usePollingHook(!ReviewsWidget ? checkReviewsIsReady : null, 700);

    /**
     * Init with the product SKU
     */
    const init = useCallback(
        async (selector, skus) => {
            if (ReviewsWidget && skus) {
                let reviewWidget;

                // Styles for the widget can be edited in productReviewWidgetOptions.
                const reviewIoData = { ...productReviewWidgetOptions };
                reviewIoData.options.product_review.sku = skus;

                try {
                    reviewWidget = new ReviewsWidget(selector, { ...reviewIoData, store: REVIEWS_IO_STORE, widget: 'polaris' });
                } catch (e) {
                    // silently fail
                }

                return reviewWidget;
            }

            return () => {};
        },
        [ReviewsWidget]
    );

    return [init, ReviewsWidget];
};

export default useReviewsProductWidgetScript;
