/**
 *
 * @param {*} src - script url
 * @param {*} callback - callback to run if script is initiated.
 * @returns script - for testing
 */
const addScript = (src, callback = () => {}, attributes = []) => {
    const scriptElem = document.querySelector(`script[src='${src}']`);
    /**
     * Checks if the script has already been added to the DOM, run callback an exit.
     */
    if (scriptElem) {
        callback();
        return scriptElem;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('src', src);

    attributes.forEach((attr) => {
        script.setAttribute(attr.key, attr.value);
    });

    script.onload = callback;
    document.body.appendChild(script);

    return script;
};

export default addScript;
